import RestDataService from '@yakit/core/api/RestDataService'

/**
 * Customer Rest Api additions
 */
 export default function(opts){

  let detApi = RestDataService({ ...opts, key: 'autocash/paymentDetail' })

  detApi.copy = async (ids) => {
    await detApi.fetch({method: 'post', path: 'copy', json: {ids}}).json()
  }

  detApi.bulkCreate = async (data) => {
    return await detApi.fetch({method: 'post', path: 'bulk?jobSource=pasteInsert', json: data}).json()
   }
  
   detApi.deleteForPayment = async (paymentId, paymentDetailIds) => {
    return await detApi.fetch({method: 'post', path: 'deleteForPayment', json: {paymentId, paymentDetailIds}}).json()
  }

  /**
   * amount sums for payment
   * @param {Object} payment
   */
  detApi.sumTotals = async (payment) => {
    //projections: ['amount':'sum', 'payAmount':'sum', 'discAmount':'sum'],
    const params = {
      projections: { amount:'sum', payAmount:'sum',  discAmount:'sum'},
      q: { paymentId: payment.id }
    }
    let searchParams = detApi.setupQueryParams(params)
    const res = await detApi.fetch({ searchParams }).json()
    const dta = res.data[0]

    const payAmount = parseFloat(dta['payAmount'])
    const amount = parseFloat(dta['amount'])
    const discAmount = parseFloat(dta['discAmount'])
    let outOfBalance
    //FIXME how to get the current payments data, should have an field in the ds here? would make it easier
    if (!Number.isNaN(payAmount)) { outOfBalance = payment.amount - payAmount }

    return {
      amount,
      payAmount,
      discAmount,
      outOfBalance,
    }

  }

  return detApi
}

