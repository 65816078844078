import { get, writable } from 'svelte/store';
import { makeQueryStore } from '@yakit/core/resource/QueryStore'
import { extend } from '@yakit/core/objectz';

/**
 * Customer Rest Api additions
 */
function TranQuery(){
  //const detailApi = TranApi({ky: paymentQuery.dataApi.ky})
  const tranQuery = makeQueryStore({ key: 'ar.tran' })
  const totals = writable({})
  const getTotals = () => { get(totals) }
  const updateTotals = async () => {
    const sumData = await tranQuery.dataApi.sumTotals(get(tranQuery.q))
    totals.set(sumData)
  }

  const clearRestrictSearch = () => {
    tranQuery.dataApi.restrictSearch = {}
  }

  const unsub = tranQuery.currentPage.subscribe(async page => {
    if(page.data) {
     await updateTotals()
    }
  })

  const rpc = (name: string, data: object) => {
    return tranQuery.dataApi.rpc(name, data)
  }

  extend(tranQuery, {
    totals,
    updateTotals,
    /** gets the total values */
    getTotals(){
      return get(totals)
    },

    clearRestrictSearch,
    async reload(){
      await tranQuery.query()
      await updateTotals()
    }
  })

  return tranQuery
}

export default TranQuery
