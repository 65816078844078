import RestDataService from '@yakit/core/api/RestDataService'

/**
 * SyncJob Rest Api additions
 */
export default function(opts){

  const api = RestDataService({ ...opts, key: 'rally/syncJob' })

  api.data = async (id) => {
    const result = await api.fetch({method: 'get', path: `/${id}`}).json()
    return result.data
  }
  
  api.payload = async (id) => {
    const result = await api.fetch({method: 'get', path: `/${id}/payload`}).json()
    return result
  } 

  return api
}

