import Logger from '@yakit/core/logger'; let log = Logger(false);
import mainAppState, { type AppState } from '@yakit/ui/AppState'
import { user, custId, type User } from './user'
import { writable } from 'svelte/store'
import routesHolder from './pages/routes/holder';
import ipaySidebarConfig from './ipay/sidebarConfig'
import type { KyInstance } from '@yakit/core/api'
import type { Writable } from 'svelte/store'


let appState

export interface RcmAppState extends AppState {
  custId: Writable<number>;
  config: Writable<object>;
  init(): Promise<void>;
  setupAppConfig(): Promise<void>;
}

export function makeAppState({ ky, authService, apiUrl }: { ky: KyInstance, authService: any, apiUrl: string }) {
  mainAppState.apiUrl = apiUrl
  // mainAppState.ky = ky

  appState = AppStateFactory({ ky, appState: mainAppState as RcmAppState, authService })
  appState.init()

  return appState
}

/**
 * Factory for the rest api endpoints
 */
export function AppStateFactory({ ky, appState, authService }: { ky: KyInstance, appState: RcmAppState, authService: any }){

  //sets to 0 for now, will get set if its a customer after login. and for testing can set to simulate cust
  appState.custId = custId
  appState.user = user
  appState.config = writable({})

  appState.auth = authService
  appState.info = {
    name: 'RCM Greenbill', // name of your project
    author: '9ci', // author's name or company name
    description: 'Get Paid Faster', // brief description
    version: '4.0.x',
    copyrightYear: '2022'
  }

  /**
   * Init is called on a first visit or a page refresh.
   */
  appState.init = async () => {
    log("******* RcmAppState init called")
    let token = localStorage.getItem('bearer')
    if(token &&  await authService.validate()){
      appState.isAuthenticated.set(true)
    } else {
      appState.requireLogin()
    }

    const unsub = appState.isAuthenticated.subscribe(async isAuthed => {
    if (isAuthed) {
        appState.setupAppConfig()
     }
    });
  }

  appState.setupAppConfig = async () => {
    try {
      const uiConfig: any = await ky.get('appConfig/ui').json()
      let _user: User = uiConfig.currentUser as User
      log("currentUser", _user)
      user.set(_user)
      appState.config.set(uiConfig.config || {})
      if(_user.isCustomer) {
        await routesHolder.setupIpayRoutes()
        appState.sidebarConfig.set({nav: ipaySidebarConfig})
        log("ipaySidebarConfig", ipaySidebarConfig)
        custId.set(_user.orgId)
      } else {
        await routesHolder.setupRcmRoutes()
        log("uiConfig.sidebar", uiConfig.sidebar)
        appState.sidebarConfig.set(uiConfig.sidebar)
      }

    } catch(error) {
      const resp = error.response
      if (resp?.status === 401) {
        appState.requireLogin()
      } else {
        throw error.message
      }
    }
  }

  return appState
}

export default appState
